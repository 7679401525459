import { render, staticRenderFns } from "./org-info-detail.vue?vue&type=template&id=40e7fa10&scoped=true&"
import script from "./org-info-detail.vue?vue&type=script&lang=js&"
export * from "./org-info-detail.vue?vue&type=script&lang=js&"
import style0 from "./org-info-detail.vue?vue&type=style&index=0&id=40e7fa10&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e7fa10",
  null
  
)

export default component.exports