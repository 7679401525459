<template>
    <div class="box">
        <div class="background"></div>
        <div class="homepage-header flex x-left">
            <Header type="2" :navList="navList" :navCurrentId="navCurrentId">
                <div slot="search">
                    <div style="width:200px;"></div>
                </div>
            </Header>
        </div>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 文章详情 -->
                    <div class="guidelist-article t-l pb20">
                        <p class="fs22 strong color333 row-2">{{ infomation.news_title }}</p>
                        <p class="fs12 color999 row-1 mt20">
                            <span v-if="infomation.create_time">{{ infomation.create_time }}</span>
                            <span class="ml20" v-if="infomation.source"><span class="strong">来源：</span>{{ infomation.source }}</span>
                            <span class="ml20"><span class="strong">阅读：</span>{{ infomation.browsing }}次</span>
                        </p>
                    </div>
                    <div v-if="infomation.news_content" v-html="infomation.news_content" class="t-l mt15 content-art mb20"
                        @click="handleFollowEvent($event)"></div>
                    <!--热点资讯点赞收藏  -->
                    <div v-if="type == 2 && infomation.news_content"  class="flex x-right">
                        <span class="ml15 cursor" @click="getExeLikeCollect('2')">
                            <i :class="['med', infomation.is_like == 1 ? 'med-dianzan' : 'med-dianzan_huaban']"></i>
                            <span class="ml5">{{ infomation.is_like == 1 ? '已点赞' : '点赞' }}</span>
                        </span>
                        <span class="ml15 cursor" @click="getExeLikeCollect('1')">
                            <i :class="['med', infomation.is_collect == 1 ? 'med-shoucang1' : 'med-shoucang']"></i>
                            <span class="ml5">{{ infomation.is_collect == 1 ? '已收藏' : '收藏' }}</span>
                        </span>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!infomation.news_content && !loading" />
                </a-spin>
            </div>
            <div class="homepage-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { orgDetailList } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import { urlReplace } from '@/utils/urlReplace';
import {  expertJumpDetail } from '@/utils/jumpPageMethods'
import { mapState } from 'vuex';
import storage from 'store'

export default {
    name: "index",
    components: {
        Header, Footer, layoutRight, hotTopicItem
    },
    data() {
        return {
            navList: orgDetailList(storage.get('commiittee_id')),//顶部菜单
            navCurrentId: 1,//当前页面菜单对应下标
            newId:this.$route.query.id,
            type:this.$route.query.type,
            infomation: {},
            loading: true,
            newss: '',
            userUid: [],
            followList: [],
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '即将召开会议', // 标题
                    methods: 'get',
                    moreLink:'/organization/org-meeting?committee_id=' + storage.get('commiittee_id'),
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        is_now_year_meeting: 1,
                        committee_id:storage.get('commiittee_id'),
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '回放视频', // 标题
                    methods: 'get',
                    moreLink:'/organization/org-playback?committee_id=' + storage.get('commiittee_id'),
                    data: {
                        page: 1,
                        limit: 2,
                        committee_id_search: storage.get('commiittee_id'),
                    },   // 参数条件
                },
                meetingAlbum: {
                    show: true, // 是否显示
                    title: '会议相册', // 标题
                    methods: 'post',
                    moreLink:'/organization/org-album?committee_id=' + storage.get('commiittee_id'),
                    data: {
                        page: 1,
                        limit: 1,
                        committee_id: storage.get('commiittee_id'),
                    },   // 参数条件
                },
            },
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        this.getInfoDetail()
    },
    watch: {
        $route(to, from) {
            if (to.fullPath != from.fullPath) {
                this.layoutRightConfig = null
                this.newId = to.query.id
                this.type = to.query.type
                this.getInfoDetail()
            }
        }
    },
    methods: {
        expertJumpDetail,
        handleFollowEvent(e) {
            let id = e.target.id
            if (id && id.indexOf('follow-btn-') > -1) {
                let index = id.lastIndexOf("-");
                index = id.substr(index + 1, id.length)
                if (this.followList[index] == 0) {
                    this.request.post('FollowExperts', { experts_uid: this.userUid[index] }).then(res => {
                        if (res.data.code == 200) {
                            this.getInfoDetail()
                        }
                    })
                }
            }

        },
        getInfoDetail() {
            this.loading = true
            let data = {
                new_id: this.newId,
                type: this.type
            }
            let { layoutRightConfig } = this;
            this.request.post('GetNewsDetail', data).then(res => {
                if (res.data.keywords) {
                    for (let key in layoutRightConfig) {
                        key != 'correlationInfo' && (layoutRightConfig[key].data.keyword = res.data.keywords)
                    }
                }
                if (res.data.subject_ids) {
                    for (let key in layoutRightConfig) {
                        key != 'correlationInfo' && (layoutRightConfig[key].data.subject_ids = res.data.subject_ids || '')
                    }
                }
                
                if (res.data && res.data != '') {
                    this.infomation = res.data
                    let { news_content, expert_avatar, expert_user_id, expert_org, expert_name } = this.infomation;
                    let regx = /\[\[\[.*?\]\]\]/g;
                    let gzbtn = ''
                    let newss = ''
                    // let news = '[[[expert-person]]][[[expert-person="cDvwdYms1M3r9wSD1Gr2qQ_d_d"]]][[[expert-person="hWCXEiM_xNWqGZskDnzTd8A_d_d"]]]'
                    news_content = news_content.replace(/\/search-pages\/roster\/roster/g,'/#/job-warehouse/rollCall');
                    this.infomation.news_content = news_content
                    let userList = this.infomation.news_content.match(regx)
                    // console.log(userList)
                    if (userList && userList.length > 0) {
                        for (var i = 0; i < userList.length; i++) {
                            let index = i
                            let hasUid = userList[i].indexOf("=")
                            if (hasUid != -1) {
                                userList[i] = userList[i].match(/"(\S*)"/)[1]
                                this.userUid[i] = userList[i]
                            } else {
                                this.userUid[i] = expert_user_id
                            }
                            this.request.post('ExpertsData', { experts_uid: this.userUid[i] }).then(res => {
                                this.followList[index] = res.data.fans_status
                                let { avatar, real_name, org_cnname, user_id } = res.data
                                if (avatar == '') {
                                    avatar = require('@/assets/images/person-default.png')
                                }
                                if (!this.followList[index]) {
                                    gzbtn = '<span class="ml10" id="follow-btn-' + index + '" style="border:1px solid #c00000;padding:2px 10px;border-radius:5px;color:#c00000;cursor:pointer;">+关注</span>'
                                } else {
                                    gzbtn = '<span class="ml10" id="follow-btn-' + index + '" style="border:1px solid #cccccc;padding:2px 10px;border-radius:5px;color:#cccccc;">已关注</span>'
                                }
                                let origin = window.location.origin;
                                newss = '<div style="width: 100%;height: 80px;border: 1px solid #eeeeee;border-radius:10px;" class="info-item flex x-between mb20 pt5 pb5 pl10 pr10 y-center"><div class="flex x-left y-center"><img style="width: 70px;height: 70px;object-fit: cover;object-position: top;border-radius: 50%;float:left;" class="info-item-avatar" src=' + avatar + ' alt="" /><div style="height: 50px;float:left;margin-left:20px;margin-top:10px;" class="info-item-text flex-column x-between t-l ml20"><p style="margin-bottom:0;"><span class="color000 fs14">' + real_name + '</span>' + gzbtn + '</p><span class="color999 fs12">' + org_cnname + '</span></div></div><a href=/#/person-detail?uid=' + user_id + ' style="width: 100px;height: 30px;border-radius: 20px;border: 1px solid #3c56d3;color: #3c56d3;cursor: pointer;" class="info-item-btn t-c fs12 flex y-center x-center">前往专家主页</a></div>'
                                if (hasUid != -1) {
                                    this.infomation.news_content = this.infomation.news_content.replace('[[[expert-person="' + userList[index] + '"]]]', newss)
                                } else {
                                    this.infomation.news_content = this.infomation.news_content.replace('[[[expert-person]]]', newss)
                                }
                                this.infomation.news_content = urlReplace(this.infomation.news_content, (vid, el) => {
                                    videoObject.init(el, vid);
                                })
                            })
                        }
                    } else {
                        this.infomation.news_content = urlReplace(this.infomation.news_content, (vid, el) => {
                            videoObject.init(el, vid);
                        })
                    }
                } else {
                    this.infomation = {}
                }

                this.loading = false

            })
        },
        // 点赞收藏
        getExeLikeCollect(operate){
            this.request.post('GetExeLikeCollect',{
                id:this.infomation.id,
                type:2,
                operate
            }).then(res=>{
                // 收藏1 点赞2
                if(operate == 1){
                    let is_collect = this.infomation.is_collect
                    if (is_collect) {
                        is_collect = 0
                    } else {
                        is_collect = 1
                    }
                    this.infomation.is_collect = is_collect
                }else{ 
                    let is_like = this.infomation.is_like
                    if (is_like) {
                        is_like = 0
                    } else {
                        is_like = 1
                    }
                    this.infomation.is_like = is_like
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    margin-right: 30px;
    .interview-list{
        min-height: 500px;
    }
    .bb {
        border-bottom: 1px solid #D8D8D8;
    }

    .hot-topic-box:last-child .bb {
        border-bottom: 0;
    }
    .guidelist-article {
        width: 100%;
        border-bottom: 1px dashed #D8D8D8;

        p {
            margin-bottom: 0;
        }
    }
    .content-art{
        width: 100%;
        /deep/ img {
            max-width: 100%;
            height: auto;
        }
    }
    .med-shoucang1,.med-dianzan{
        color: #FFA45D;
    }
    .cursor{
        cursor: pointer;
    }
}
.homepage-right {
    width:400px;
}
@media screen and (max-width:768px){
    .homepage-content{
        width: 100%;
        padding: 10px;
        display: block;
        .homepage-list{
            width: 100%;
        }
        .homepage-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>